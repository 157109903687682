<template>
  <div :style="computedStyle">
    <yandex-map
      ref="yandexMap"
      :settings="settings"
      :coords="coords"
      :zoom="zoom"
      :options="optionsMap"
      :controls="controls"
      :show-all-markers="showAllMarkers"
      @map-was-initialized='initHandler'
      @click="onClick"
    >
      <ymap-marker v-for="marker in markersFiltered"
        :key="marker.id"
        :marker-id="marker.id"
        :coords="marker.coords"
        :options="marker.options"
        :properties="marker.properties "
        @balloonopen="bindListener(marker.plugin)"
        @balloonclose="unbindListener"
        marker-type="placemark"
        :balloon-template="balloonTemplate(marker.descriptionBalloon)"
      >
        <!-- <div slot="balloon">
          <div>{{marker.properties.iconCaption}}</div>
        </div> -->
      </ymap-marker>
    </yandex-map>
    <slot></slot>
  </div>
</template>
<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import mixin from '../mixins'
import FilterBuilder, { EComponentTypes } from '../utils'
export default {
  name: 'a-maps',
  components: { yandexMap, ymapMarker },
  inject: {
    addMainTab: { default: () => {} },
    forceUpdateSettingsPanel: { default: () => () => {} }
  },
  mixins: [mixin],
  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    yandexMaps: {
      type: Object,
      default () {
        return {
          coords: {
            latitude: null,
            longitude: null
          },
          width: '100%',
          height: '300px',
          limit: '10',
          isClickCoords: false
        }
      },
      editor: 'YandexMaps'
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    }
  },
  data () {
    return {
      settings: {
        apiKey: this.yandexMaps.keyLicense,
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1'
      },
      polyline: null,
      isPutCoordinates: false,
      isPolylineDraw: false,
      coordsClick: '',
      optionsMap: {
        autoFitToViewport: 'always'
      },
      zoom: 14,
      coords: [ ],
      markers: [],
      initialData: []
    }
  },
  computed: {
    markersFiltered () {
      return this.markers
    },
    showAllMarkers () {
      if (this.markers.length === 1 || this.markers.length === 0) {
        return false
      }
      return true
    },
    controls () {
      if (!this.yandexMaps.controls) {
        return [
          'fullscreenControl',
          'geolocationControl',
          'routeEditor',
          'rulerControl',
          'searchControl',
          'trafficControl',
          'typeSelector',
          'zoomControl'
        ]
      } else {
        return this.yandexMaps.controls
      }
    },
    computedStyle () {
      let css = this.CSS
      css += `;width: ${this.yandexMaps.width || '100%'}`
      css += `;height: ${this.yandexMaps.height || '300px'}`
      return css
    },
    dataFilters () {
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.maps
      )

      return builder.buildAsQueryParams()

      // let filters = []
      // if (this.filters) {
      //   this.filters.forEach((item) => {
      //     let type = `eq`
      //     if (item.isXref) {
      //       type = `eqx`
      //     }
      //     if (!item.type || item.type === 'field') {
      //       if (this.getModel()[item.attribute] && item.alias) {
      //         filters.push(`${item.alias},${type},${this.getModel()[item.attribute]}`)
      //       }
      //     } else if (item.type === 'constant' && item.alias) {
      //       filters.push(`${item.alias},${type},${item.attribute}`)
      //     }
      //   })
      // }
      // return filters
    }
  },
  watch: {
    dataFilters (val) {
      if (this.yandexMaps.type === 'registry' && this.yandexMaps.registryId) {
        this.getRegistryMarket()
      }
    },
    markersFiltered (val) {
      if (val.length === 1) {
        this.coords = val[0].coords
      }
    },
    'yandexMaps.isClickCoords': {
      handler (val) {
        if (!val) return
        this.drawBtnGeoMetka()
      }
    },
    'yandexMaps.isClickPolyline': {
      handler (val) {
        if (!val) return
        this.drawBtnPolyline()
      }
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  async mounted () {
    if (this.yandexMaps.centerLatitude && this.yandexMaps.centerLongitude) {
      this.coords = [ this.yandexMaps.centerLatitude, this.yandexMaps.centerLongitude ]
    } else {
      this.coords = [ 56.30079074563954, 43.983161331262245 ]
    }
    console.log(this.yandexMaps)
    // setTimeout(async () => {
    //   await this.initMap()
    // }, 300)
  },
  methods: {
    drawBtnGeoMetka () {
      let buttoncontent = {
        data: {
          // content: 'Указать геометку',
          title: 'Указать геометку',
          image: 'icon/differentIcons/geometka.svg'
        },
        options: {
          maxWidth: [0, 180, 180],
          selectOnClick: false
        }
      }
      let button = new ymaps.control.Button(buttoncontent)
      button.events.add('click', (e) => {
        this.getCoordinate()
      })
      this.yMaps().controls.add(button, { float: 'right' })
    },
    drawBtnPolyline () {
      let buttonStartDrawing = {
        data: {
          content: 'Рисовать линию'
          // title: 'Указать начало линии'
          // image: 'icon/differentIcons/geometka.svg'
        },
        options: {
          maxWidth: [280, 280, 280],
          selectOnClick: false
        }
      }
      let button = new ymaps.control.Button(buttonStartDrawing)
      button.events.add('click', (e) => {
        this.PolylineCoordinate()
      })
      this.yMaps().controls.add(button, { float: 'right' })
    },
    savePolyline () {
      this.getModel()[`attr_${this.yandexMaps.polylineCards}_`] = [...this.polyline.geometry.getCoordinates()].join()
    },
    balloonTemplate (val) {
      let valueParse
      try {
        valueParse = JSON.parse(val)[0].name
      } catch (error) {
        valueParse = val
      }
      if (val) return `<div>${valueParse}</div>`
      if (!val) return ''
    },
    yMaps () {
      let mapComponent = this.$refs.yandexMap
      let map = mapComponent.myMap
      return map
    },
    async initHandler (map) {
      await this.initMap()
      if (this.yandexMaps.isClickCoords) {
        this.drawBtnGeoMetka()
      }
      if (this.yandexMaps.isClickPolyline) {
        this.drawBtnPolyline()
      }
      if (this.yandexMaps.polylineCards && this.getModel()[`attr_${this.yandexMaps.polylineCards}_`]) {
        let coordsPolyline = this.getModel()[`attr_${this.yandexMaps.polylineCards}_`]
        // получить координаты вида [ [1,2],[1,2] ]
        const coords = coordsPolyline.split(',').reduce((acc, el, idx) => {
          if (idx % 2 !== 0) {
            acc[acc.length - 1].push(el)
          } else {
            acc.push([el])
          }
          return acc
        }, [])

        this.drawPolyline(coords)
      }
    },
    drawPolyline (coords) {
    // Создаем ломаную.
      let me = this
      let cursor = this.yMaps().cursors.push('grab')
      cursor.setKey('grab')
      let myPolyline = new ymaps.Polyline([
        // Указываем координаты вершин.
        ...coords
      ], { }, {
        strokeColor: '#00000088',
        strokeWidth: 4
        // Максимально допустимое количество вершин в ломаной.
        // editorMaxPoints: 6,
      })
      // Добавляем линию на карту.
      this.yMaps().geoObjects.add(myPolyline)
      // центрировать ломанную
      // this.yMaps().setBounds(myPolyline.geometry.getBounds())
      // Включаем режим редактирования.
      myPolyline.editor.startEditing()
      myPolyline.editor.options.set({
        menuManager: function (editorItems, model) {
          editorItems.push({
            id: 'StopEditing',
            title: '<span style="white-space:nowrap;">Сохранить<span>',
            onClick: function (polygon, pointIndex) {
              myPolyline.editor.stopDrawing()
              me.savePolyline()
            }
          },
          {
            title: 'Удалить линию',
            onClick: function () {
              me.yMaps().geoObjects.remove(myPolyline)
              me.getModel()[`attr_${me.yandexMaps.polylineCards}_`] = ''
            }
          })

          return editorItems
        }
      })
      this.polyline = myPolyline

      this.isPolylineDraw = false
    },
    PolylineCoordinate () {
      let cursor = this.yMaps().cursors.push('arrow')
      cursor.setKey('crosshair')
      this.isPolylineDraw = true
    },
    getCoordinate () {
      let cursor = this.yMaps().cursors.push('arrow')
      cursor.setKey('crosshair')
      this.isPutCoordinates = true
    },
    bindListener (pluginName) {
      console.log('pluginName', pluginName)
      if (pluginName) {
        import(`@/plugins/${this.$config.project}/${pluginName}`)
          .then((Plugin) => {
            let instance = new Plugin.default(this)
            instance.execute()
          }).catch(() => {
            console.log(`Plugin: ${this.pluginName} not found`)
          })
      }
    },
    unbindListener (e) {
      console.log('deletelistener')
    },
    drawMarkers (e) {
      let cursor = this.yMaps().cursors.push('grab')
      cursor.setKey('grab')
      this.getModel()[`attr_${this.yandexMaps.cardLatitude}_`] = e.get('coords')[0].toFixed(4)
      this.getModel()[`attr_${this.yandexMaps.cardLongitude}_`] = e.get('coords')[1].toFixed(4)
      if (this.markers.length === 0) {
        this.markers.push({ id: Math.random(), coords: [...e.get('coords')] })
      }
      if (this.markers.length === 1) {
        this.markers[0]['coords'] = e.get('coords')
      }
      this.isPutCoordinates = false
    },
    onClick (e) {
      if (!this.isPutCoordinates && !this.isPolylineDraw) return false
      if (this.isPutCoordinates) this.drawMarkers(e)
      if (this.isPolylineDraw) this.drawPolyline([e.get('coords')])
    },
    async getRegistryMarket () {
      this.initialData = []
      if (!this.yandexMaps.coords.latitude && !this.yandexMaps.coords.longitude) return false
      let markets = await this.getRegistryData(this.yandexMaps.registryId)
      this.initialData = markets
      let latitude = `attr_${this.yandexMaps.coords.latitude}_`
      let longitude = `attr_${this.yandexMaps.coords.longitude}_`
      let iconCaption = `attr_${this.yandexMaps.iconCaption}_`
      let iconColor = `attr_${this.yandexMaps.iconColor}_`
      let descriptionBalloon = `attr_${this.yandexMaps.descriptionBalloon}_`
      this.markers = markets.reduce((acc, elem) => {
        if (elem[latitude] && elem[longitude]) {
          acc.push(
            {
              id: elem.id,
              data: elem,
              plugin: this.yandexMaps.markerPlugin || '',
              coords: [elem[latitude], elem[longitude]],
              properties: {
                iconCaption: elem[iconCaption] || ''
              },
              options: {
                iconColor: elem[iconColor] || '#1e98ff'
              },
              descriptionBalloon: elem[descriptionBalloon] || ''
            }
          )
        }
        return acc
      }, [])
    },
    async getRegistryData (entityId) {
      let params = []
      this.dataFilters.forEach((filter, index) => {
        params.push(`filter[${index}]=${filter}`)
      })
      if (!params.length) return []
      let data = await this.$http
        .get(`${this.$config.api}/registryservice/registry/${entityId}/data?${params.join('&')}&limit=${this.yandexMaps.limit || 10}&offset=0`)
      return data.data.data
    },
    async getExtendObjectMarket () {
      this.initialData = []
      if (!this.yandexMaps.coords.latitude && !this.yandexMaps.coords.longitude) return false
      // поля расширенной таблице
      let markets = await this.getExtendObjectData(this.yandexMaps.extendObject)
      this.initialData = markets
      let latitude = markets.find(item => this.yandexMaps.coords.latitude === item.id).name
      let longitude = markets.find(item => this.yandexMaps.coords.longitude === item.id).name
      let iconCaption = markets.find(item => this.yandexMaps.iconCaption === item.id) || { name: '' }
      let iconColor = markets.find(item => this.yandexMaps.iconColor === item.id) || { name: '' }
      // значения полей расширенной таблице
      let dataMarkets = await this.$http.get(`${this.$config.api}/datawarehouseservice/extended_object/${this.yandexMaps.extendObject}`)
      this.markers = dataMarkets.data.reduce((acc, elem) => {
        if (elem[latitude] && elem[longitude]) {
          acc.push(
            {
              id: elem.id,
              data: elem,
              plugin: this.yandexMaps.markerPlugin || '',
              coords: [elem[latitude], elem[longitude]],
              properties: {
                iconCaption: elem[iconCaption] || ''
              },
              options: {
                iconColor: elem[iconColor] || '#1e98ff'
              }
            }
          )
        }
        return acc
      }, [])
    },
    async initMap () {
      if (this.yandexMaps.type === 'registry' && this.yandexMaps.registryId) {
        await this.getRegistryMarket()
      }
      if (this.yandexMaps.type === 'extend_object' && this.yandexMaps.extendObject) {
        await this.getExtendObjectMarket()
      }
    },
    async getExtendObjectData (id) {
      let response = await this.$http.get(`${this.$config.api}/bieditor/extended_object_fields?extended_object_id=${id}`)
      return response.data
    }
  }
}
</script>

<style scoped>
.ymap-container {
  height: 100%;
  width: 100%;
}
</style>
